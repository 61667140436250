<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/' + database" title="Apelación Conectividad" collection="apelacion_conectividad" templateTable="TableConectividadForm" templateTableModal="TableConectividadModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const store = useStore()
    const database = computed(() => store.getters.getProduccion)
    const mongo = computed(() => store.getters.getMongoURL)
    //const mongo = 'https://api.idastage.com/api/'

    const startDate = computed(() =>
      moment()
        .startOf('week')
        .format('YYYY-MM-DD')
    )
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        page_id: 'page_id',
        fecha: 'fecha',
        tipo_documento: 'tipo_documento',
        rut: 'rut',
        pasaporte: 'pasaporte',
        nombres: 'nombres',
        apellidos: 'apellidos',
        email: 'email',
        computador: 'computador',
        internet_hogar: 'internet_hogar',
        internet_movil: 'internet_movil',
        registro_social: 'Doc. Registro social de hogares',
        terminos_condiciones: 'terminos_condiciones',
      },
      query: {
        collection: 'apelacion_conectividad',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
        date_field: 'createdAt',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
